.main-body{
    background-color: #f9f9f9;
    margin-top: 8vh;
    min-height: 100vh;
    margin-left: 230px;
}

.main-body-no-padding{
    background-color: #f9f9f9;
    margin-top: 50px;
    min-height: 100vh;
    margin-left: 230px;
}

@media only screen and (max-width: 600px){
    .main-body{
        margin-left: 0px;
        margin-top: 7vh;
        padding: 0px;
    }

    .main-body-no-padding{
        margin-left: 0px;
        margin-top: 50px;
        padding: 0px;
    }
}

#b_ul li{
    width: 90px;
    text-align: center;
    border: solid thin #891AFF;
    border-bottom: solid thick #891AFF;
    margin-right: 10px;
    color: black;
}

.initialisation-container{
    background-color: #81D4FA;
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
}

